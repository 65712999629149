import React from "react";
import {Helmet} from 'react-helmet'

import Layout from "../components/layout.js"
import './../styles/kalkulator-opon.scss'

const contactPage=() => {

    const calcFn=() => {

        var rimDiameterOld=parseInt(document.getElementById("rimDiameterOld").value)
        var rimDiameterNew=parseInt(document.getElementById("rimDiameterNew").value)

        var tireWidthOld=parseInt(document.getElementById("tireWidthOld").value)
        var tireWidthNew=parseInt(document.getElementById("tireWidthNew").value)

        var tireProfileOld=parseInt(document.getElementById("tireProfileOld").value)
        var tireProfileNew=parseInt(document.getElementById("tireProfileNew").value)


        var wheelDiameterOld=(rimDiameterOld*25.4+tireProfileOld*tireWidthOld*0.02).toFixed(1);
        document.getElementById('wheelDiameterOld2').innerHTML=wheelDiameterOld+' mm';

        var wheelDiameterNew=(rimDiameterNew*25.4+tireProfileNew*tireWidthNew*0.02).toFixed(1);
        document.getElementById('wheelDiameterNew2').innerHTML=wheelDiameterNew+' mm';

        var wheelDiameterDifferent=(wheelDiameterNew-wheelDiameterOld).toFixed(2);
        document.getElementById('wheelDiameterDifferent2').innerHTML=wheelDiameterDifferent+' mm';

        var speedDifferent=((wheelDiameterDifferent/wheelDiameterNew)*100).toFixed(1)
        document.getElementById('speedDifferent2').innerHTML=speedDifferent+' %';


        var realSpeed=100+parseFloat(speedDifferent);
        const realSpeedString=realSpeed+' km/h';

        document.getElementById('realSpeed2').innerHTML=realSpeedString;
    }

    return (
        <Layout>
            <Helmet>
                <title>Kalkulator opon, zamienniki opon, opony</title>

                <link rel="canonical" href="https://oponytychy.pl/kalkulator-opon" />
                <meta name="description" content="Kalkulator opon - to narzędzie dzięki, któremu dobierzesz zamienniki opon do samochodu. Zamienniki opon dobierane są na podstawie obliczeń w różnicy średnic kół oraz różnicy w prędkości na danych oponach (koła wzorcowego i zamiennika opony)." />
                <meta name="keywords" content="kalkulator opon, kalkulatoropon, zamienniki opon, zamiennikiopon, opony, opony letnie, opony zimowe, opony wielosezonowe, opony całoroczne, kalkulator rozmiaru opon, kalkulator do opon, rozmiar opon, kalkulator opon felg, kalkulator felg, rozmiar opon kalkulator, kalkulator zamienników opon" />
            </Helmet>

            <div className="main calculator">
                <div className="container_12">
                    <div>
                        <div className="grid_4">
                            <div className="box1">
                                <div className="indents5">
                                    <h2 className="p3-1"><span>Parametry <strong>opon:</strong></span></h2>

                                    <strong>Szerokość opony</strong>
                                    <p className="p1">Jest podawana w milimetrach. W samochodach osobowych od 125mm do nawet 300mm.</p>
                                    <br />
                                    <strong>Profil opony</strong>
                                    <p className="p1">Jest to podany w % stosunek wysokości opony do jej szerokości, 65 oznacza, że wysokość opony to 65% jej szerokości a nie 65mm.</p>
                                    <br />
                                    <strong>Średnica felgi</strong>
                                    <p className="p1">Średnica osadzenia opony na feldze podawana w calach.Jest to: średnica felgi, na jaką może być zamontowana opona.</p>
                                    <br />
                                    <strong>Średnica Koła</strong>
                                    <p className="p2">Jest to podana w milimetrach średnica koła od styku opony z podłożem do czubka bieżnika po przeciwnej stronie.</p>
                                    <br />
                                </div>
                            </div>
                        </div>

                        <div className="grid_8 last-col">
                            <div className="box1">
                                <div className="indents5">
                                    <h2 className="p4"><span>Kalkulator <strong>Opon:</strong></span></h2>

                                    <p className="p2"><span className="color-2">Aby obliczyć zamiennik opony wpisz rozmiar opony dotychczasowej, na której jeździsz a następnie kliknij przycisk "oblicz".                        Wyświetlone zostaną średnice kół dla opony dotychczasowej i opony po zmianach. Wyświetlona zostanie także różnica w średnicy koła dla opony wzorcowej i <strong>zamiennika opony</strong> w milimetrach. Pokazanie zostanie także różnica w prędkości dla opony wzorcowei i zamienniku opony podana w % oraz prędkość rzeczywista jaka będzie się wyświetlała na liczniku na <strong>oponach wzorcowych</strong> i <strong>zamiennikach opon</strong>. </span></p>
                                    <br />

                                    <p className="p2"><span className="color-2">UWAGA!</span></p>
                                    <p className="p2">1. Montaż opony o większej średnicy od opony wzorcowej spowoduje, że na liczniku prędkości będzie mniejsza niż jest w rzeczywistości.</p>
                                    <p className="p2">2. Montaż opony o mniejszej średnicy niż opona wzorcowa spowoduje przekłamanie licznika &quot;na plus&quot; - licznik będzie pokazywał prędkość większą niż rzeczywista.                      </p>

                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="column-1">&nbsp;</th>
                                                <th className="column-2">Szerokość opony:</th>
                                                <th className="column-3">Profil opony:</th>
                                                <th className="column-4">Średnica felgi:</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="column-1"><strong>Dotychczasowe opony:</strong></td>
                                                <td className="column-2"><input className="input_cell" aria-label="Szerokość dotychczasowej opony" type="text" id="tireWidthOld" size="10" /></td>
                                                <td className="column-3"><input className="input_cell" aria-label="Profil dotychczasowej opony" type="text" id="tireProfileOld" size="10" /></td>
                                                <td className="column-4"><input className="input_cell" aria-label="Średinica dotychczasowej opony" type="text" id="rimDiameterOld" size="10" /></td>
                                            </tr>
                                            <tr>
                                                <td className="column-1"><strong>Opony po zmianach:</strong></td>
                                                <td className="column-2"><input aria-label="Szerokość nowej opony po zmianach" type="text" id="tireWidthNew" size="10" /></td>
                                                <td className="column-3"><input aria-label="Profil nowej opony po zmianach" type="text" id="tireProfileNew" size="10" /></td>
                                                <td className="column-4"><input aria-label="Średinica nowej opony po zmianach" type="text" id="rimDiameterNew" size="10" /></td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="submit_button">
                                        <input type="button" aria-label="Oblicz różnicę parametrów opon" value="Oblicz" onClick={calcFn} />
                                    </div>

                                    <p className="p2"><span>Średnica dotychczasowego koła:</span><span className="calculator__result"><span id="wheelDiameterOld2"></span></span></p>
                                    <p className="p2"><span>Średnica koła po zmianach:</span><span className="calculator__result"><span id="wheelDiameterNew2"></span></span></p>
                                    <p className="p2"><span>Różnica w średnicy koła [mm]:</span><span className="calculator__result"><span id="wheelDiameterDifferent2"></span></span></p>
                                    <p className="p2"><span>Różnica prędkości [%]:</span><span className="calculator__result"><span id="speedDifferent2"></span></span></p>
                                    <p className="p2"><span>Prędkość rzeczywista na liczniku przy 100km/h:</span><span className="calculator__result"><span id="realSpeed2"></span></span></p>

                                    <br />
                                    <p className="p2"><span className="color-2">PAMIĘTAJ!</span></p>
                                    <p className="p2">1. <strong>Zamiennik opon</strong> dobieraj tylko do rozmiaru fabrycznie montowanego w twoim samochodzie (można sprawdzićw instrukcji obsługi),</p>
                                    <p className="p2">2. <strong>Zamiennik opony </strong>powinien mieć indeks nośności taki jak opona wzorcowa lub większy,</p>
                                    <p className="p2">3. <strong>Zamiennik opon</strong> powinien mieć indeks prędkości taki jak opona wzorcowa lub większy,</p>
                                    <p className="p2">4. Prawidłowo dobrany <strong>zamiennik </strong>mieści się w przedziale od -2% do +1,5% dla średnicy zewnętrznej koła,</p>
                                    <p className="p2">5. Dobrą praktyką jest sprawdzenie czy wyliczony <strong>zamiennik</strong> znajduje się w instrukcji samochodu dostarczonej przez producenta.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default contactPage
